import React from 'react'
import { Container } from 'react-bootstrap'
import '../styles/Navigation.css'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

export default function Navigation () {
  const url = useLocation();
  return (
    <div className='navigation' style={{display: 'flex'}} fluid>
      <Link to='/' className={url.pathname === '/' ? 'active' : ''}>Home</Link>
      <Link to='/commissions' className={url.pathname === '/commissions' ? 'active' : ''}>Commissions</Link>
    </div>
  )
}
